import { autoinject, computedFrom } from "aurelia-framework";
import environment from "../environment";

@autoinject()
export class BrandService {
  @computedFrom('environment.brand')
  get brand() {
    return environment.brand;
  }

  get policyStatementUrl(): string {
    switch (this.brand) {
      case 'pg':
        return 'https://www.premierguarantee.com/privacy-policy/';
      case 'labc':
        return 'https://www.labcwarranty.co.uk/privacy-policy/';
      default:
        return null;
    }
  }

  get brandName() {
    switch (this.brand) {
      case 'pg':
        return 'Premier Guarantee';
      case 'labc':
        return 'LABC Warranty';
      default:
        return '';
    }
  }

  get brandTelephone() {
    switch (this.brand) {
      case 'pg':
        return '0800 107 8446';
      case 'labc':
        return '0800 183 1755';
      default:
        return '';
    }
  }
}
