import { autoinject } from "aurelia-framework";
import { TokenService } from "./tokenService";

@autoinject()
export class DocumentDownloadService {

    constructor(private tokenService: TokenService) {

    }

    download(documentId: string) {
        const token = this.tokenService.getToken();
        let url = `/api/file/${documentId}`;
        if (token) {
            url += `?token=${token}`
        }
        window['downloadingFile'] = true;
        window.location.href = url;
        window['downloadingFile'] = false;
    }
}
