import { autoinject } from "aurelia-framework";
import { DialogService, DialogSettings } from "aurelia-dialog";
import { PromptDialog } from "resources/dialogs/prompt";

@autoinject()
export class TokenService {
    constructor(private dialogService: DialogService) { }

    private static _token: string = null;

    async isSharedComputer(): Promise<boolean> {
        const isShared = localStorage.getItem('shared-computer');

        if (isShared === null) {
            return await this.dialogService
                .open({ viewModel: PromptDialog, model: 'Are you using a shared computer?' } as DialogSettings)
                .whenClosed(response => {
                    if (!response.wasCancelled) {
                        const result = response.output as boolean;
                        this.setSharedComputer(result);
                        return result;
                    }
                });
        } else {
            return isShared === 'true';
        }
    }

    setSharedComputer(sharedComputer: boolean): void {
        if (sharedComputer) {
            localStorage.removeItem('application-token');
            localStorage.setItem('shared-computer', 'true');
        }
        else {
            localStorage.setItem('shared-computer', 'false');
            if (TokenService._token) {
                this.setToken(TokenService._token);
            }
        }
    }

    async setToken(token: string) {
        if (!await this.isSharedComputer()) {
            localStorage.setItem('application-token', token);
        }

        TokenService._token = token;
    }

    getToken(): string{

        const storedToken = localStorage.getItem('application-token');
        if (storedToken) {
            TokenService._token = storedToken;
        }

        return TokenService._token;
    }
}
